
.button {
    border: 0 solid;
    border-radius: 0;
    box-sizing: border-box;
    outline: none;
    &:not(.isDisabled){
        cursor:pointer;
    }

    &.button-default {
        background: transparent;
        border-color: $garlock-dark-blue;
        color: $garlock-dark-blue;
        border-width: 1px;
        @include withButtonAnimation();
        
        &.isDisabled {
            color: $garlock-light-gray;
            border-color: $garlock-light-gray-w75; 
        }


        &:not(.isDisabled){
            &:hover {
                border-color: $garlock-dark-blue;
            }
        } 
    }

    &.button-primary{
        background: $garlock-dark-blue;
        color: $white;

        &.isDisabled {
            background: $garlock-light-gray;
        }

        &:not(.isDisabled){
            &:hover {
                background: $garlock-light-blue;
            }
        }
    }
    &.button-danger{
        background: $garlock-red;
        color: $white;

        &:not(.isDisabled){
            &:hover {
                background: $black;
                color:$white;
            }
        }
    }

    &.button-primary, &.button-secondary,&.button-danger{
        transition: border cubic-bezier(.38,.04,.35,.96) 600ms, color cubic-bezier(.38,.04,.35,.96) 600ms,background cubic-bezier(.38,.04,.35,.96) 600ms;
    }

    &.button-secondary{
        background: #dddddd;
        color: $garlock-dark-blue;
        border: 1px solid $garlock-dark-blue;

        &.isDisabled {
            background: #dddddd;;
            color: $garlock-light-gray;
            border-color: $garlock-light-gray-w75; 
        }

        &:not(.isDisabled){
            &:hover {
                color: $garlock-light-blue;
                border: 1px solid $garlock-light-blue;
            }
        }
    }

    &.button-size-default {
        height: 48px;
        padding: 0 40px;
        font-size: 1rem;
    }
    &.button-size-large{
        height: 40px;
        width:100%;
        position: relative;
        padding: 0 80px;
        font-size: .875rem;
    }

    &.button-size-small{
        height: 40px;
        padding: 0 20px;
        font-size: .875rem;
    }

    &.button-size-tiny{
        height: 30px;
        padding: 0 10px;
        font-size: .75rem;
    }
}