@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}


@mixin withAnimation {
	transition: all 0.5s .1s cubic-bezier(0.075, 0.82, 0.165, 1);
}

@mixin backgroundAnimatedHidded {
    position: absolute;
    content: ' ';
    width: 100%;
    height: 2px;
    left: 0;
	bottom: 0;
    z-index: 0;
    background: $garlock-light-gray-w75;
    @include withAnimation();
}

@mixin showBackgroundAnimated {
	height: 100%;
}

@mixin defaultFontInput () {
    margin-left: 15px;
    margin-right: 15px;
	margin-top: .5rem;
	z-index: 2;
    @include fontDetail( 16px, normal, normal, 400)
}

@mixin breakLine{
	position: absolute;
	display: inline;
	top: 0px;
	width: 100%;
	height: 1px;
	align-self: center;
	content: ' ';
	background: #e6e6e6;
	z-index: 1;
	@include withAnimation();
}

@mixin withButtonAnimation {
	transition: border cubic-bezier(.38,.04,.35,.96) 600ms;
}