.chat-list-container {
    width: 100%;
    height: 90px;
    // background-color: peru;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #ccc;

    .selected {
        background-color: #2888DF !important;
        height: 100%
    }

    .selected-text{
        color: white !important;
    }
    
    &:last-child {
        border-bottom: 1px solid #ccc;
    }
   
    &:hover {
        background-color: #F5F5F5;
    }

    .avatar-container {
        padding-left: 2%;
        padding-right: 3%;
    }

    .chatList-Avatar {
        width: 47px;
        height: 47px;
    }

    .chatList-content{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .chatList-text {
        display: flex;
        flex-direction: column;
        justify-self: flex-start;
        width: 50%;

        .chatList-title {
            text-align: left;
            font: normal normal bold 18px/25px;
            letter-spacing: 0px;
            color: #2888DF;
            opacity: 1;
        }
        .preview {
            font-size: 13px;
        }
    }

    .rest-container {
        display: flex;
        flex-direction: column;
        right: 0px;
        left: auto;
        width: 100px;
        height: 90px;
        align-items: flex-end;
        justify-content: center;

        .date-element {
            height: 15px;
            width: 100%;
            font-size: 13px ;
            color: #4D4F5C;
            text-align: right;
        }

        .read-element {
            color: white;
            width: 16px;
            height: 16px;
            background: #2ECC71 0% 0% no-repeat padding-box;
            opacity: 1;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            font: normal normal normal 10px/30px Poppins;
            letter-spacing: 0px;
        }
        .pending-element {
            color: white;
            width: 16px;
            height: 16px;
            background: #E4AD38 0% 0% no-repeat padding-box;
            opacity: 1;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            font: normal normal normal 10px/30px Poppins;
            letter-spacing: 0px;
        }
    }
}

