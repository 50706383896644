.list-container {
    // background-color: red;
    height: 635px;
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .chatList {
        width: 100%;
        height: 500px;
        overflow: auto;
    }
}

