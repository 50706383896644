@import "../../assets/scss/colors";

html {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}
.content {
  height: 100%;
  width: 100%;
}
main {
  height: calc(100% - 160px);
  position: relative;
  overflow: hidden;
  margin-left: 250px;
  margin-bottom: 50px;
}
.icons {
    display: inline-block;
  width: 2rem;
  height: 2rem;
  background: white;
  mask-size: cover;
  &.a{
   mask: url('../../assets/Icons/users.svg') no-repeat;
  }

  &.b{
    mask: url('../../assets/Icons/products.svg') no-repeat;
   }
   &.c{
    mask: url('../../assets/Icons/clients.svg') no-repeat;
   }
   &.d{
    mask: url('../../assets/Icons/plants.svg') no-repeat;
   }
   &.e{
    mask: url('../../assets/Icons/installedproducts.svg') no-repeat;
   }
   &.f{
    mask: url('../../assets/Icons/supportrequests.svg') no-repeat;
   }
  }


.mainPage{
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  margin-top: 10rem;
}
.mainPage .card{
  height:180px;
  width: 250px;
  display: inline-block;
  position: relative;
  margin: auto;
  margin-left: 4rem;
  background-color: $garlock-dark-blue;
}
.card-body{
  position: relative;
  margin: auto;
  padding: 20% 0;
  text-align: center;
  label{
    margin-left: 1rem;
    color: white;
  }

  .icons{
    vertical-align: middle;
  }
  h2{
    color: white;
  }
}

.cardWrapper{
  height:auto;
  width:1000px;
  position: relative;
  margin:auto;
  margin-top:4rem;
}


.logo-login {
  background: url("../../assets/logo.png") no-repeat;
  position: relative;
  margin: auto;
  margin-top: 10rem;
  display: block;
  width: 15rem;
  height: 100px;
  background-size: 15rem auto;
  box-sizing: border-box;
}

.form-label {
  cursor: default;
  font-weight: 700;
  color: black;
  transform-origin: left top;
  -webkit-transform: translateY(0) scale(0.7);
  transform: translateY(0) scale(0.7);
  padding-left: 15px;
  white-space: nowrap;
  
}
.form-link *:hover input {
  color: $garlock-light-blue;
}
.register-container {
  display: block;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  width: 80%;
  height: auto;
  min-height: 70%;
  position: relative;
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
}
.mainLoginContainer img {
  height: 90px;
  width: 90px;
  margin-bottom: 1rem;
}
.mainLoginContainer h2,
p {
  color: $garlock-dark-blue;
}
.login-container {
  display: block;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  flex-direction: column;
  position: relative;
  margin: auto;
  margin-top: 0%;
  height: auto;
  min-height: 40%;
  width: 35%;
  background-color: white;
  padding-left: 0%;
  padding-bottom: 2%;
  padding-top: 2%;
}

.recovery-container {
  text-align: center;
}

.spinnerSpan{
  width:100%;
  *{
    margin-left: 50%;
  }
}

#root {
  height: 100%;
  width: 100%;
}
form {
  width: 100%;
  position: relative;
  height: 100%;
}

.selectorform {
  position: relative;
  margin: auto;
  width: 40%;
  top: 15%;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  padding-top: 2rem;
  padding-left: 4rem;
  padding-right: 4rem;
}
.btnalign {
  position: relative;
  margin: 0 auto;
  display: flex;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.btnalign * {
  margin: 0 auto;
}
.detailcontainer {
  display: block;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.5);
  width: 80%;
  height: auto;
  min-height: 70%;
  position: relative;
  padding-top: 3%;
  padding-left: 5%;
  padding-right: 5%;
}

body {
  height: 100%;
  width: 100%;
}
.login {
  height: 100%;
  width: 100%;
  position: relative;
  overflow-y: hidden;
}


.input-container {
  position: relative;
  width: 100%;
  height: auto;
}

.button-span * {
  cursor: pointer;
}
.Users,
.clients,
.Portfolios,
.plants,
.ProductLines,
.Industries,
.SuccessCases,
.ProductStyles,
.Products,
.Applications,
.Distributors,
.Chat {
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  position: absolute;
  align-items: center;
  overflow-y: scroll;
}
.garlock-logo {
  width: 10rem;
  height: 5rem;
}
.garlock-logo a {
  position: relative;
  top: 20px;
  left: 20px;
  background: url("../../assets/logo.png") no-repeat;
  display: block;
  width: 10rem;
  height: 60px;
  background-size: 10rem auto;
  box-sizing: border-box;
}
.table-toolbar {
  position: relative;
  height: 80px;
  width: 100%;
  background-color: rgba(0, 46, 93, 1);
  vertical-align: middle;
}
.table-toolbar button {
  position: relative;
  float: right;
  vertical-align: middle;
}
.table-toolbar a {
  position: relative;
}
a label {
  cursor: pointer;
}
.table-display {
  position: relative;
  margin: auto;
  width: 90%;
  align-items: center;
  height: auto;
  margin-bottom: 6rem;
}
.spacer {
  padding-top: 2%;
}

.btnspan .buttonlink {
  position: absolute;
  right: 0;
}
.btnspan {
  width: 90%;
  margin: auto;
  height: 3.5rem;
  position: relative;
  margin-top: 1rem;
}
.center-table {
  width: 90%;
  margin: auto;
}
.btnspan a{
  position: absolute;
  right:0;
}

.buttonspan button {
  position: absolute;
  right: 0;
}
.btnspan .input-group button {
  margin-left: 10px;
}
.input-group input{
  margin-top:.2rem;
}
.btnspan .input-group input:focus {
  background-color: #f7f7f7;
  padding-right: 0;
  border-radius: 0.25rem;
}

.editbutton button {
  height: 3rem;
  width: 3rem;
  background-color: white;
  color: rgba(0, 46, 93, 1);
  border: none;
  transition: cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.2s;
}
.editbutton button:hover {
  color: $garlock-light-blue;
  transition: cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.2s;
}

.add-button button:hover {
  transition: cubic-bezier(0.755, 0.05, 0.855, 0.06) 0.2s;
  background-color: $garlock-light-blue;
}

.form-control:disabled {
  background-color: $disabled-grey;
  // color:#0000008A;
  opacity: 0.4;
}

a .FormField.FormFieldInputText {
  cursor: pointer;
}
.form-control {
  border: none;
  color: black;
  border-bottom: 1px solid #e6e6e6;
}
.mainContainer {
  margin: auto;
  width: 75%;
  position: relative;
  margin-bottom: 5rem;
}

.mainLoginContainer {
  margin: auto;
  width: 75%;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 5%;
}

.button-span {
  margin: auto;
  width: 100%;
  height: auto;
  position: relative;
  margin-top: 5%;
  padding-bottom: 5%;
}

div.button-span > button{
  position: relative;
  margin: auto;
}

.content-container {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
}

.buttonCustom {
  position: relative;
  display: inline-block;
  padding-right: 0;
}

.image-link {
  z-index: 200;
}
.image-link a {
  background-color: $disabled-grey;
  color: $garlock-dark-blue;
  border-radius: 20px;
  padding: 3px 8px;
  margin-top: 10px;
  z-index: 200;
}
.image-link a:hover {
  color: $garlock-light-blue;
}

.isHidden {
  display: none;
}

.recoverpassword a {
  font-size: large;
  color: $garlock-dark-blue;

  &:hover {
    color: $garlock-light-blue;
  }
}

.welcome {
  text-align: center;
}

.welcome h2 {
  color: $garlock-dark-blue;
  padding-bottom: 1rem;
}

.welcome label {
  color: $garlock-dark-blue;
  font-size: large;
}
.title-span {
  margin-top: 3rem;
}


.modal-body .successLottie{
  width: 100%;
  height: 300px;
  margin-right:0;
  text-align: center;
  img{
    position: relative;
    display: block;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  h2{
    color: $garlock-dark-blue;
  }
}


.buttonspan {
  position: relative;
  margin: auto;
  width: 80%;
  padding-top: 5%;
  padding-bottom: 2rem;
}

.buttonspan .buttonCustom {
  position: absolute;
  right: 0;
  display: inline-block;
  padding-right: 0;
}
.buttonspan .buttonCustom * {
  position: relative;
  margin-left: 5px;
}
.detailcontainer h2 {
  padding-bottom: 1rem;
}

.flex {
  min-height: 60pt;
  position: absolute;
  top: 40%;
  left: 50%;
}

.resources * {
  display: inline-block;
}

.resources h6 {
  position: absolute;
  bottom: 0;
}
.resources button {
  position: absolute;
  right: 0;
}
.page-link{
  border:none;
  
}


.resources {
  position: relative;
  width: 100%;
  height: 3rem;
}

.recoverpassword {
  position: relative;
  margin: auto;
  margin-top: 2rem;
  display: block;
  text-align: center;
}

.toolbar-collapse {
  display: none;
}
.pagination{
  width: 90%;
  margin:auto;
}
.page-item.active .page-link{
  border-bottom:1px solid $garlock-light-gray;
  background-color: white;
  color: $garlock-dark-blue;
}
.page-link{
  border:none;
  
}


@keyframes loading {
  0% {
    width: 50pt;
    height: 50pt;
    margin-top: 0;
  }
  25% {
    height: 4pt;
    margin-top: 23pt;
  }
  50% {
    width: 4pt;
  }
  75% {
    width: 50pt;
  }
  100% {
    width: 50pt;
    height: 50pt;
    margin-top: 0;
  }
}
@media only screen and (max-width: 1220px) {
  .button.button-size-large {
    margin: auto;
    width: 100%;
    padding: 0;
  }

  .login-container {
    width: 50%;
    margin: auto;
  }

  .cardWrapper{
    width: 800px;
    .card{
      margin: auto;
      vertical-align: middle;
      height: 170px;
      width: 200px;
      margin-left: 2rem;
    }
  }
  .selectorform{
    width: 70%;
  }
}

@media only screen and (max-width: 970px) {
  .cardWrapper{
    .card{
      margin: auto;
      vertical-align: middle;
      height: 120px;
      width: 150px;
      margin-left: 2rem;
    }
    .card-body{
      padding: 10% 0;
      label{
        font-size: 0.8rem;
      }
      h2{
        font-size: 1rem;
      }
    }
  }
}
@media only screen and (max-height: 1000px) {
  .login{
    overflow-y: scroll;
  }
}

@media only screen and (max-height: 800px) {
  .login{
    overflow-y: scroll;
  }
}


@media only screen and (max-width: 768px) {
.selectorform{
  width: 70%;
  padding:1rem;
}
  .table-display{
    margin-bottom: 12rem;
  }
  .button.button-size-large {
    margin: auto;
    width: 100%;
    padding: 0;
  }

h3{
  font-size: 1rem;
}

  h2{
    font-size: 1rem;
  }
  
  h6{
    font-size: .7rem;
  }

  .image-link a{
    font-size: x-small;
  }
  .login-container {
    width: 80%;
    margin: auto;
  }

  .button.button-size-default{
    width:100%;
    padding:10px;
    font-size: x-small;
    height: 40px;
  }
  .button.button-size-small{
    font-size: x-small;
    height: 30px;
  }
  .side-drawer.open {
    left: -253px;
  }
  
  .buttonspan .button.button-size-default{
    width:60px;
    position: absolute;
    right:0;
    font-size: x-small;
    padding-top:0;
    padding-bottom:0;
    height: 40px;
  }

  .buttonCustom{
    position: absolute;
    right:0;
  }
  .buttonCustom .button.button-size-default{
    width:60px;
    position: relative;

    font-size: x-small;
    padding-top:0;
    padding-bottom:0;
    height: 40px;
  }
  main {
    height: 100%;
    margin: 0;
  }

  .mainContainer {
    width: 100%;
    margin-bottom:10rem;
  }

  .logo-login {
    background: url("../../assets/logo.png") no-repeat;
    position: relative;
    margin: auto;
    margin-top: 30%;
    display: block;
    width: 10rem;
    height: 70px;
    background-size: 10rem auto;
    box-sizing: border-box;
  }
  
  .recoverpassword a{
    font-size: small;
  }

  .toolbar-logo {
    height: 40px;
    width: 7rem;
    background-size: 7rem auto;
  }

  .cardWrapper{
    width: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .toolbar-collapse {
    display: block;
  }
}

@media only screen and (max-width: 574px) {
  .cardWrapper{
    .card{
      margin: auto;
      vertical-align: middle;
      height: 90px;
      width: 120px;
      margin-left: 2rem;
    }
    .card-body{
      padding: 10% 0;
      label{
        font-size: 0.6rem;
      }
      h2{
        font-size: .8rem;
      }
    }
  }
}
@media only screen and (max-width: 400px) {
  .cardWrapper{
    width:100%;
    .card{
      margin: auto;
      vertical-align: middle;
      height: 70px;
      width: 100px;
      margin-left: 5%;
    }
    .card-body{
      padding: 10% 0;
      label{
        font-size: 0.6rem;
        margin: 0;
        margin-bottom: 10%;
      }
      h2{
        font-size: .8rem;
      }
      .icons{
        display: none;
      }

    }
  }
}

.backButton{
  position: absolute;
  left: 0;
  padding: 0 20px;
  padding-top: 5px;
  background-color: transparent;
  border:none;
}
.backIcon{
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background: $garlock-dark-blue;
  mask-size: cover;
   mask: url('../../assets/Icons/back.svg') no-repeat;
}