.contact-container{
    height: 635px;
    background-color: #F0F2F2;
    // background-color: green;
    display: flex;
    flex-direction: column;
    align-items: center;

    .first-container {
        width: 100%;
        .avatar-container {
            margin-top: 10%;
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: column;
            
            .title {
                margin-top: 5%;
                font: normal normal normal 31px/37px Helvetica;
            }

            .avatar {
                width: 97px;
                height: 97px;
            }
        }

        .contact-info {

            margin-top: 10%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            width: 100%;
            height: 40%;

           

            .text-container {
                display: flex;
                align-items: center;
                margin-bottom: 5%;

                 .icon {
                   margin-right: 10px;
                   margin-left: 5%;
                }

                .contact-text {
                    text-align: left;
                    font: normal normal normal 18px/26px Helvetica;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                }
            }

            
        }
    }

    .action-container {
        margin-top: 5%;
        .action-title {
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            width: 336px;
            height: 55px;
            background: #F5F5F5 0% 0% no-repeat padding-box;
            opacity: 1;
            display: flex;
            align-items: center;

            .title {
                margin-left: 5%;
                text-align: left;
                font: normal normal medium 18px/24px Roboto;
                letter-spacing: 0px;
                color: #000000DE;
                opacity: 1;
            }
        }

        .button-container {
            width: 100%;
            height: 170%;
            display: flex;
            align-items: center;
            justify-content: center;
            
            button {
                color: white;
                width: 285px;
                height: 44px;
                background: #2980B9;
                border-radius: 4px;
                padding: 0;
                border: none;
                &:hover {
                    background: #226592;
                }

            }
            .disabled {
                background-color: #ccc;
                color: black;
                &:hover {
                    background: #ccc !important;
                }
            }
            
        }
        
    }
}