@import '../../../assets/scss/colors';


.FormField:not(.FormFieldInputCheckbox) {
    margin-bottom: 1rem;
    
    .FormFieldWrapper {
        position: relative;

        
        .FormFieldElement {
            border-radius: 0;
            background: 0 0;
            border: 0;
            padding: 0 15px;
            width: 100%;
            z-index: 2;
            outline: 0;
            border-bottom: 1px solid #e6e6e6;
            height: 48px;
            left: 0;
            margin-left: 0;
            margin-top: 1em;
            position: relative;
            font-family: inherit;
    
            &:focus + .FormFieldLabel {
                cursor: default;
                font-weight: 700;
                color: $black;
                transform: translateY(0) scale(.7);
                white-space: nowrap; 
            }
    
            &:focus + .FormFieldLabel + .FormFieldBackground {
                opacity: 1;
                transform: scaleY(1);
            }
            
            &:disabled + .FormFieldLabel + .FormFieldBackground {
                opacity: .4;
                transform: scaleY(1);
                z-index: 200;
            }
    
            &:required + .FormFieldLabel > .FormFieldLabelText:after {
                content: '*';
                display: inline;
                padding-left: 2px;
                vertical-align: top;
            }
    
            &.isInvalid {
                border-bottom: 1px solid $garlock-red;
            }

            &.formfield-label{
                height:35px,
            }
            &.formfield-label .image-link a{
                background-color: $disabled-grey;
                color: $garlock-dark-blue;
                border-radius: 20px;
                padding: 3px 8px;
                margin-top:10px;
            }
            &.formfield-label .image-link a:hover{
                color:$garlock-light-blue;
            }
            &.password{
                padding-right:35px;
            }
        }
    
        
    
        .FormFieldBackground {
            bottom: 0;
            display: block;
            height: calc(100% - 1em);
            left: 0;
            position: absolute;
            z-index: -2;
            opacity: 0;
            width: 100%;
            background-color: #f7f7f7;
            transition: opacity cubic-bezier(.38,.04,.35,.96) 400ms,transform cubic-bezier(.38,.04,.35,.96) 400ms,-webkit-transform cubic-bezier(.38,.04,.35,.96) 400ms;
            transform-origin: 0 100%;
            transform: scaleY(0);
        }
    }
}




.FormFieldValidation {
    height: auto;
    padding-top: .5rem;
    position: relative;

    .FormFieldMessageContainer {
        font-size: .75rem;
        font-weight: 700;
        line-height: 1.125rem;
        margin-left: 15px;
        margin-right: 0;
        position: relative;

        .FormFieldMessage {
            &.FormFieldMessageError {
                color: $garlock-red;
            }
        }
    }
}

.FormField:not(.FormFieldInputCheckbox) {
    .FormFieldLabel {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 15px;
        cursor: text;
        color: $garlock-light-gray;
        height: 1em;
        line-height: 1em;
        transform: translateY(24px) translateY(.5em);
        transform-origin: 0 0;
        transition: all 400ms cubic-bezier(.38,.04,.35,.96) 400ms;
    }

    .FormFieldElement.notEmpty + .FormFieldLabel {
            cursor: default;
            font-weight: 700;
            color: $black;
            transform: translateY(0) scale(.7);
            white-space: nowrap; 
    }
}

.FormFieldInputCheckbox {
    .FormFieldElement {
        position: absolute;
        visibility: hidden;
        vertical-align: top;

        &:required + .FormFieldLabel > .FormFieldLabelText:after {
            content: '*';
            display: inline;
            padding-left: 2px;
            vertical-align: top;
        }
    }

    .FormFieldElement:checked + .FormFieldLabel .FormFieldIcon .Icon {
        display: block;
    }

    .isInvalid {
        color: $garlock-red;
    }

    .FormFieldLabel {
        margin-top: 0;
        padding-top: 0;
        color: initial;
        cursor: pointer;
        display: inline-block;
        font-size: .875rem;
        padding-left: 30px;
        user-select: none;
        width: 100%;
        left: 0;
        margin-left: 0;
        position: relative;
        height: auto;

        .FormFieldIcon {
            background: $garlock-light-gray-w75;
            border-bottom: 2px solid $garlock-light-gray;
            bottom: -1px;
            display: inline-block;
            height: 20px;
            margin-left: -30px;
            left: 0;
            position: relative;
            width: 20px;

            .Icon {
                display: none;
                path {
                    fill: $garlock-dark-blue;
                }
            }
        }

        .FormFieldLabelText {
            display: inline;
            font-size: 1rem;
            margin-left: 10px;
            vertical-align: top;
        }
    }
}


 
  
  .upload-btn-wrapper input[type=file] {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
    }
    

@media only screen and (max-width: 768px) {
    .FormFieldElement {
        font-size: x-small;
    }
    .form-control{
        
        font-size: x-small;
    }
    #root > div > main > form > div > div > div.detailcontainer > div.input-container > div.FormField.FormFieldInputFile > div > label.FormFieldElement.notEmpty.formfield-label > div{
        margin-top: .6rem;
    }
}

.inputDisabledElement{
    border-radius: 0;
    background: 0 0;
    border: 0;
    padding: 0 15px;
    width: 100%;
    z-index: 2;
    outline: 0;
    border-bottom: 1px solid #e6e6e6;
    height: 38px;
    text-align: left;
    left: 0;
    margin-left: 0;
    position: relative;
    font-family: inherit;
    
}

.iconEye{
    position:absolute;
    top:1.8em;
    right:5px;
    width:24px;
    height:24px;
    background: black;
    z-index: 100;
    cursor:pointer;
    mask: url('../../../assets/Icons/eye.svg')no-repeat;
}