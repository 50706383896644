.notification {
    background-color: #ccc;
    width: 450px;
    height: 125px;
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    flex-direction: row;

    .closeContainer {
        height: 100%;
        width: 50px;
        margin-right: 0px;
        margin-left: auto;
        background-color: red;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: rgba(255, 0, 0, 0.459);
        }

        .dismiss {
            color: white;
        }
    }

    .text {
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin: 10px 0px 0px 15px;

        .title {
            text-align: left;
            font: normal normal bold 18px/25px;
            padding-bottom: 10px; 
        }

        .content {
            text-align: left;
            font: normal normal bold 18px/25px;
        }
    }
}

.hidden {
    opacity: 0;
}