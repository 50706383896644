.chatDisplay {
    display: flex;
    align-items: center;
    flex-direction: row;
    // background-color: red;
    width: 1548px;
    border: 1px solid #ccc;

}

.chat-title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #E7E7E7;
    align-content: center;
    border-bottom: 1px solid #ccc;
    &.list {
      top: 233px;
      left: 311px;
      width: 355px;
      height: 69px;
      color: #00346C;

    }

    &.messages {
      top: 231px;
      left: 664px;
      width: 855px;
      height: 69px;
      font-weight: bold;
      color: #000000;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
    }

    &.contact {
      top: 233px;
      left: 1517px;
      width: 336px;
      height: 69px;
      background-color: #F5F5F5;
    }
}

.title {
    margin-top: 0%;
    margin-bottom: 0%;
    &.rest {
      margin-left: 5%;
    }
}

/* The switch - the box around the slider */
.switch {
  margin-left: 5%;
  margin-right: 5%;
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-top: 0%;
  margin-bottom: 0%;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2ECC71;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2ECC71;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}