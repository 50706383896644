@import '../../../assets/scss/colors';

.footer{
    bottom: 0;
    position: fixed;
    width: 100%;
    background-color: $garlock-dark-blue;
    height: 50px;
    z-index: 200;
}

.footer h3{
    color: white;
}

