table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
}
table tr {
  border-radius: 20px;
}

td ul{
  padding-left: 1rem;
  list-style: disc;
}


.main-container {
  position: relative;
  display: block;
  height: auto;
  width: 100%;
  border: none;
}

table th,
table td {
  padding: 1em;
  padding-left: 1rem;
  text-align: left;
}

table th {
  font-size: 0.85em;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}

tbody tr:hover{
    background-color: rgba(229, 238, 245, 1);
    transition: cubic-bezier(0.445, 0.05, 0.55, 0.95).03s;
    cursor: pointer;
}
tbody tr{
  transition: cubic-bezier(0.445, 0.05, 0.55, 0.95).2s;
}

td.last-td{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30rem;
}


@media only screen and (max-width: 1250px) {
  td.email-td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 5rem;
  }
}
@media only screen and (max-width: 1100px) {
  td.last-td{
    max-width: 15rem;
  }
}
@media only screen and (max-width: 768px) {
  td.last-td{
    max-width: 10rem;
  }
  tbody td{
    font-size: xx-small;
  }
  table *{
    overflow-x: hidden;
    text-overflow: ellipsis;
    font-size: x-small;
  }

  
}