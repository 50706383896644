
@import '../../../assets/scss/colors';

.toolbar{
    z-index: 200;
    box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
}


.nav-toolbar{
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
    padding-right:2%
}
.toolbar-nav-items ul{
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
}
.toolbar-logo{
    margin-top: 2%;
    background:url('../../../assets/logo.png')no-repeat;
    width: 14rem;
    height: 80px;
    background-size: 14rem auto;
}
.toolbar-logo a{
    position: absolute;
    top: 20px;
    background:url('../../../assets/logo.png')no-repeat;
    display: block;
    width: 10rem;
    height: 60px;
    background-size: 14rem auto;
    box-sizing: border-box;
}
.toolbar-nav-items li{
    padding: .5rem;
}
.toolbar-nav-items a{
    color: blueviolet;
    text-decoration: none;
}
.spacer{
    flex: 1;
}
.toolbar-nav-items a:hover{
    color: yellow;
    text-decoration: none;
}
.toolbar-logo a{
    color: blueviolet;
    text-decoration: none;
    font-size: 2rem;
}

.name{
    display: block;
    position: relative;
    font-size: initial;
}

.position{
    font-size: smaller;
}

section{
    padding-top: 10px;
    padding-right: 15px;
}

a label{
    display: block;
}

.toolbar-list{
    list-style: none;
}

.toolbar-list a li label{
    display: inline-block;
}

.toolbar-list a li{
    display:flex;
}

.profile-nav{
    display:block;
    position: relative;
    margin-right: 0.8rem;
}

.toolbar-spacer{
    width:13.7%;
}

@media only screen and (max-width: 768px) {
    .profile-nav{
        display:none;
    }
    .icon6{
        display:none;
    }
}

