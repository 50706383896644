.message-container {
    height: 635px;
    border-right: 1px solid #ccc;
    border-left: 1px solid #ccc;

    display: flex;
    flex-direction: column;

    .messages {
        height: 562px;
    }

    .input-container {
        background-color: #F0F2F2;
        width: 100%;
        height: 73px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}