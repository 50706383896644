@import '../../../assets/scss/_colors';
.side-drawer{
    height: calc(100% - 50px);   
    background: white;
    box-shadow: 2px 0px 5px rgba(0,0,0,0.5);
    position: fixed;
    left: 0;
    width: 55px;
    max-width: 400px;
    overflow:hidden;
    margin-top: 1px;
    transition: .2s ease-out;
}

.side-drawer.open{
    width: 250px;
    transition: .25s ease-in-out;
}

li label{
    padding-top: 1%;
    font-size: large;
    font-weight: bolder;
}
.side-drawer ul{
    height: 100%;
    list-style: none;
    padding-top: 20px;
    display: flex;
    padding-left: 1.1rem;
    flex-direction: column;
    font-size: 1.2rem;
}

.icon1,.icon2,.icon3,.icon4,.icon5,.icon6,.icon7 {
    display: inline-block;
  width: 2rem;
  height: 2rem;
  background: $garlock-dark-blue;
  mask-size: cover;
}

.icon1{
    mask: url('../../../assets/Icons/users.svg')no-repeat;
}

.icon2{
    mask: url('../../../assets/Icons/clients.svg')no-repeat;
}
.icon3{
    mask: url('../../../assets/Icons/portfolios.svg')no-repeat;
}
.icon4{
    mask: url('../../../assets/Icons/products.svg')no-repeat;
}
.icon5{
    mask: url('../../../assets/Icons/supportrequests.svg')no-repeat;
}
.icon6{
    mask: url('../../../assets/Icons/user.svg')no-repeat;
    margin-bottom: .5rem;
}
.icon7{
    mask: url('../../../assets/Icons/chat.svg')no-repeat;
}
.side-drawer li  label{
    display:inline-block;
    margin-left:.8rem;
    margin-bottom:0;
    opacity: 0;
}

.side-drawer.open li label{
   opacity: 1;
}

.side-drawer li{
    display: flex;
}
.side-drawer ul a{
    height:4%;
    position: relative;
    margin-bottom: 5%;
}
.side-drawer li:hover{
    color: $garlock-light-blue;
    div{
    background: $garlock-light-blue;
    }
}
.side-drawer a{
    // padding-left: 1.5rem;
    color: $garlock-dark-blue;
    text-decoration: none;
}
.collapse-link{
    margin-left:14%;
}
.collapse-link label{
    font-size: smaller;
    font-weight: lighter;
}
a li{
    margin-top:0;
    margin-bottom: 2%;
}
.collapse.show{
    margin-bottom: 5%;
}

a.active{
    color: orange;
}

a.active div{
    background:orange;
}


@media only screen and (max-width: 768px) {
    .icon1,.icon2,.icon3,.icon4,.icon5,.icon6,.icon7 {
      margin-right:15px;
    }
}

